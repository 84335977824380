
.dropzone {
  width: 100%;
  max-width: 1000px; 
  min-height:300px;
  border: 2px dashed #ccc;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  margin: 0 auto; 
  margin-top: 5%;
  text-align: center;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

.dropzone:focus {
  outline: none;
}

.dropzone:hover {
  border-color: #007bff;
}

.dropzone__label {
  font-size: 18px;
  color: #666;
  margin-top: 20px;
}

.dropzone__input {
  display: none;
}

.dropzone__message {
  font-size: 14px;
  color: #999;
  margin-top: 10px;
}

.dropzone__message--active {
  color: #333;
}